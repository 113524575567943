.carousel-country {

    .owl-stage {
        margin-top: 40px;
        margin-bottom: 30px;
    }

    .owl-nav {
        position: absolute;
        top: 25%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        /*pointer-events: none;*/

        .owl-prev {
            position: absolute;
            left: -15px;

            span {
                color: #000000;
                font-weight: 200;
                font-size: 80px;
                height: 0px;
            }
        }

        .owl-next {
            position: absolute;
            right: 25px;

            span {
                color: #000000;
                font-weight: 200;
                font-size: 80px;
                height: 0px;
            }
        }

        .owl-item {
            padding: 30px;
        }

        button span {
            font-size: 84px;
            pointer-events: all;
            height: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

        }

        .button span:hover {
            background-color: #1c814b !important;
        }
    }

    .carousel-item {
        flex: 0 0 calc(25% - 20px);
        /* Répartit les éléments en 4 colonnes et spécifie la largeur */
        margin-right: 20px;
        /* Espacement entre les éléments */
        position: relative;
        /*pointer-events: none;*/

        .image-carousel {
            display: flex;
            justify-content: center;

            .product-title-stock {
                display: flex;
                justify-content: center;
            }

            img {
                width: 90%;
                height: 250px;
                object-fit: contain;
                object-position: center;
            }
        }

        .carousel-item-content {
            display: flex;
            flex-direction: column;
            align-items: center;

            .carousel-item-price {
                display: flex;
                justify-content: center;
                flex-direction: row;
                align-items: center;
                gap: 10px;
                position: relative;
                border-radius: 10px;
                max-height: 60px;
                padding: 0px 10px;
                margin-bottom: 25px;
                padding-top: 5px;
                position: relative;
                width: 100%;

                .bad-price {
                    position: absolute;
                    top: -5px;
                    transform: translateX(-50%);
                    left: 25%;

                    p {
                        text-decoration: line-through;
                        font: normal normal normal 12px Poppins;
                        letter-spacing: 0px;
                        color: #064C37;
                        position: relative;

                        span {
                            position: absolute;
                            top: 0px;
                            text-decoration: line-through;
                            font: normal normal normal 10px Poppins;
                            letter-spacing: 0px;
                            color: #064C37;
                        }
                    }
                }

                .promo {
                    background-color: #915832;
                    position: absolute;
                    bottom: -35%;
                    left: 50%;
                    transform: translateX(-50%);
                    border-radius: 10px;

                    p {
                        margin: 0px 10px;
                        font: normal normal bold 16px Poppins;
                        text-align: center;
                        letter-spacing: 0px;
                        color: #FFFFFF;

                    }
                }

                .price {
                    p {
                        text-align: center;
                        font: normal normal normal 20px Poppins;
                        letter-spacing: 0px;
                        color: #064C37;
                        position: relative;
                        margin-right: 15px;

                        span {
                            position: absolute;
                            top: 0px;
                            text-align: center;
                            font: normal normal normal 12px Poppins;
                            letter-spacing: 0px;
                            color: #064C37;
                        }
                    }
                }

                .available {

                    .dispo {
                        color: #008000;
                    }

                    .indispo {
                        color: #ee9a3a;
                    }

                    .indispo-rupture {
                        color: #F94A4A;
                    }

                    p {
                        font: normal normal normal 11px Poppins;
                        letter-spacing: 0px;
                        color: #064C37;
                        margin: 0;
                    }

                    .article-favoris-indicator {
                        cursor: pointer !important;
                    }


                    .favorites {
                        margin-top: 0px;
                        margin-bottom: 10px;
                    }

                    i {
                        margin-right: 3px;
                    }
                }
            }

            .product-title {
                h2 {
                    margin-top: 0;
                    margin-bottom: 10px;
                    text-align: center;
                    font: normal normal bold 18px Poppins;
                    letter-spacing: 0px;
                    color: #064C37;
                }
            }

            .product-description {
                p {
                    margin: 0;
                    text-align: center;
                    font: normal normal normal 14px Poppins;
                    letter-spacing: 0px;
                    color: #064C37;
                }
            }
        }
    }
}

.carousel-incontournable-country {

    .owl-stage {
        margin-top: 40px;
        margin-bottom: 30px;
    }

    .owl-nav {
        position: absolute;
        top: 35%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        pointer-events: none;

        .owl-prev {
            position: absolute;
            left: -15px;

            span {
                color: #000000;
                font-weight: 200;
                font-size: 80px;
                height: 0px;
            }
        }

        .owl-next {
            position: absolute;
            right: -15px;

            span {
                color: #000000;
                font-weight: 200;
                font-size: 80px;
                height: 0px;
            }
        }

        .owl-item {
            padding: 30px;
        }

        button span {
            font-size: 84px;
            pointer-events: all;
            height: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

        }

        .button span:hover {
            background-color: #1c814b !important;
        }
    }




    a {
        text-decoration: none;
    }

    .carousel-incontournable {
        display: flex;
        flex-direction: column;
        align-items: center;

        .circle-background {
            background-color: #064C37;
            width: 220px;
            /* Ajustez la taille du cercle */
            height: 220px;
            /* Ajustez la taille du cercle */
            border-radius: 50%;
            position: relative;

            img {
                width: 100%;
                height: 100%;
                object-fit: scale-down;
                object-position: center center;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .carousel-item-title {
            h2 {
                margin-top: 10px;
                margin-bottom: 0;
                color: var(--unnamed-color-064c37);
                text-align: center;
                font: normal normal 24px Poppins;
                font-weight: 600;
                color: #064C37;
            }
        }
    }
}

.carousel-footer-country {
    .owl-stage {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .owl-nav {
        position: absolute;
        top: 35%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        pointer-events: none;

        .owl-prev {
            position: absolute;
            left: -15px;

            span {
                color: #000000;
                font-weight: 200;
                font-size: 80px;
                height: 0px;
            }
        }

        .owl-next {
            position: absolute;
            right: -15px;

            span {
                color: #000000;
                font-weight: 200;
                font-size: 80px;
                height: 0px;
            }
        }

        .owl-item {
            padding: 30px;
        }

        button span {
            font-size: 84px;
            pointer-events: all;
            height: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

        }

        .button span:hover {
            background-color: #1c814b !important;
        }
    }


    .carousel-item {
        flex: 0 0 calc(20% - 20px);
        /* Répartit les éléments en 5 colonnes et spécifie la largeur */
        margin-right: 20px;
        /* Espacement entre les éléments */
        position: relative;

        .carousel-partenaire {
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
                width: 130px;
                height: 130px;
            }

            .carousel-item-title {
                h2 {
                    margin-top: 10px;
                    margin-bottom: 0;
                    color: var(--unnamed-color-064c37);
                    text-align: center;
                    font: normal normal 24px Poppins;
                    font-weight: 600;
                    color: #064C37;
                }
            }
        }
    }

    /* Styles pour les images à l'intérieur des éléments du carrousel */
    .carousel-item img {
        object-fit: contain;
        width: 100%;
        height: 200px;
    }

}